.action_icons {
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}
.welcome_text {
  font-weight: bolder !important;
  font-size: 25px !important;
}
.sign_text {
  opacity: 0.8 !important;
}
.input_field > input {
  height: 10px;
}
