.form___title__text {
  font-size: 20px !important;
  font-weight: 800 !important;
  /* color: #fe5858 !important; */
  margin: 40px 0px 7px !important;
}
.rules_text {
  font-size: 25px !important;
  text-decoration: underline;
}
.textareas_fields::placeholder {
  font-family: sans-serif !important;
}
.icons_class {
  border: 1px solid;
  border-radius: 15px;
  padding: 5px !important;
  font-size: 29px !important;
  margin-right: 20px;
  cursor: pointer;
}
.active_amenity {
  background: black !important ;
  color: white !important;
}
.property_image_class {
  object-fit: cover !important;
}
.block__container {
  width: 55px;
  height: 55px;
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.table__title {
  color: rgb(61 56 56 / 87%) !important;
  margin-left: 15px !important;
  font-size: 22px !important;
  font-weight: 700 !important;
}
.dashboard_hostizzy_properties
  > .MuiTableHead-root
  > .MuiTableRow-root
  > .MuiTableCell-root {
  font-weight: 700 !important;
  font-size: 13px !important;
  /* color: #5f6261 !important; */
}

.dashboard_hostizzy_properties
  > .MuiTableBody-root
  > .MuiTableRow-root
  > .MuiTableCell-root {
  /* font-weight: 700 !important; */
  font-size: 15px !important;
  color: #5f6261 !important;
}
.status__container {
  width: 10px;
  height: 9.5px;
  display: flex;
  justify-content: center;
  border-radius: 15px;
  color: white;
  padding: 2px;
  margin-right: 10px;
}
.active___container {
  background: #07990af5;
}
.deactive___container {
  background-color: red;
}
.hostizzy_task {
  height: 50px;
  width: 100%;
  border-radius: 10px;
  background-color: #eaeaea;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 15px;
  /* justify-content: space-between; */
}
.hostizzy__task__text {
  font-size: 14.5px !important;
  margin-left: 6px !important;
  color: #635e5e;
}
.slug__container {
  margin-right: 12px;
  width: 12px;
  height: 100%;
}
.green_slug {
  background-color: #65a238;
}
.red_slug {
  background-color: #e03a24;
}
.yellow_slug {
  background-color: #f9f10e;
}

.cover_photo_img-blur {
  filter: blur(8px);
  border: 1px solid #ccc;
  width: 365px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
}
.cover_photo_img {
  width: 365px;
  height: 300px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #ccc;
}
.prev,
.next {
  border-radius: 80px !important;
  padding: 0px !important;
  min-width: 33px !important;
  min-height: 33px !important;
  height: 33px !important;
}

.images_box_with_data > div {
  flex: 0 0 auto; /* Don't allow items to shrink or grow */
}

.images_box_with_data img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  margin: 0 3px;
  border-radius: 8px;
  border: 1px solid #ccc;
  /* position: relative; */
}

.images_box_with_data {
  display: flex;
  justify-content: flex-start;
  height: fit-content;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  gap: 1%;
}

.images_box_with_nodata {
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_img_div {
  width: 100%;
  margin-top: 1%;
  display: flex;
  justify-content: flex-end;
}

.add_images_btn {
  cursor: pointer;
  background-color: white;
  outline: none;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 0.8%;
}

.image___container {
  position: relative;
  margin: 10px;
  cursor: pointer;
}
.image___container__radio {
  position: absolute;
  top: 5px;
  left: 5px;
}

.image___container___radio__button {
  background: white !important;
  width: 18px;
  height: 18px;
  padding: 0px !important;
  border-radius: 50% !important;
}

.accordion_main {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  position: static !important;
  border-radius: 8px !important;
}

.form_container {
  margin-top: 20px;
}

/* .cover_photo_modal {
  width: 948px;
} */

.cover_photo_modal_div {
  width: 948px;
}

.custom-dialog {
  max-width: 938px !important;
  width: 938px;
}
.cross__container {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  /* border: 1px solid; */
  background: black;
  color: white;
  padding: 0px;
  height: 21px;
}
