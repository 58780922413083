.image___container {
  position: relative;
  margin: 10px;
  cursor: pointer;
}
.image___container__radio {
  position: absolute;
  top: 5px;
  left: 5px;
}

.image___container___radio__button {
  background: white !important;
  width: 18px;
  height: 18px;
  padding: 0px !important;
  border-radius: 50% !important;
}

.accordion_main {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
  position: static !important;
  border-radius: 8px !important;
}

.form_container {
  margin-top: 20px;
}

/* .cover_photo_modal {
  width: 948px;
} */

.cover_photo_modal_div {
  width: 948px;
}

.custom-dialog {
  max-width: 938px !important;
  width: 938px;
}