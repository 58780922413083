.count_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 15px 0px;
  margin-bottom: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
}

.added_property_container {
  height: fit-content;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 28px;
  border-radius: 7px;
}

.reminder_container {
  /* height: 438px; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 28px;
  border-radius: 7px;
}
@media screen and (min-width: 320px) and (max-width: 576px) {
  .dashboard_container {
    flex-direction: column;
  }
  .count_container {
    width: 100%;
    /* grid-column: span 12;
    width: 290px; */
  }
  .added_property_container {
    width: 100%;
    /* grid-column: sapn 12; */
    /* width: 300px; */
  }
  .reminder_container {
    width: 100%;
    /* grid-column: span 12; */
    /* width: 300px; */
  }
}
@media screen and (min-width: 577px) and (max-width: 976px) {
  .dashboard_container {
    flex-direction: column;
  }
  .count_container {
    width: 49%;
    /* grid-column: span 6; */
  }
  .added_property_container {
    width: 100%;
    /* grid-column: span 12; */
    /* height: 400px !important; */
  }
  .reminder_container {
    width: 100%;
    /* grid-column: span 12;
    margin-top: 32%; */
  }
}

@media screen and (min-width: 977px) {
  .dashboard_container {
    flex-direction: row;
  }
  .count_container {
    width: 24%;
    /* grid-column: span 3; */
  }
  .added_property_container {
    /* grid-column: span 7; */
    width: 49%;
    /* height: 400px !important; */
  }
  .reminder_container {
    width: 49%;
    /* grid-column: span 5; */
  }
}
