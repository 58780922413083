.sidebar__section > .css-dip3t8 {
  background-color: transparent !important;
}

@media screen and (min-width: 320px) and (max-width: 576px) {
  .sidebar__container__menu > .css-1wvake5 {
    min-width: 150px;
    width: 150px;
  }
  .css-1wvake5.ps-collapsed{
    width: 40px !important;
    min-width: 40px !important;
  }
  .ps-menuitem-root > .ps-menu-button {
    padding: 0 !important;
  }
}
@media screen and (min-width: 577px) and (max-width: 976px) {
  .sidebar__container__menu > .css-1wvake5 {
    min-width: 200px;
    width: 200px;
  }
  .ps-menuitem-root > .ps-menu-button {
    padding: 20px;
  }
}
@media screen and (min-width: 977px) {
  .sidebar__container__menu > .css-1wvake5 {
    min-width: 250px;
    width: 250px;
  }
  .ps-menuitem-root > .ps-menu-button {
    padding: 20px;
  }
}
